<template>
  <div>
    <button-top v-if="permissions.c" tooltip="Crear rol" name="Rol"
      @action="$router.push('/profiles/create')"></button-top>
    <b-table :items="items" :fields="fields" :busy="load" :current-page="current_page" :per-page="per_page">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <span v-if="permissions.u" class="ml-1 cursor-pointer" v-b-tooltip.hover.top="'Editar rol'"
          @click="$router.push(`/profiles/update/${row.item.id}`)">
          <feather-icon icon="MoreVerticalIcon" size="18" />
        </span>
      </template>
    </b-table>
    <b-row class="mt-1">
      <b-col>
        <b-pagination v-model="current_page" :total-rows="items.length" :per-page="per_page"
          aria-controls="my-table"></b-pagination>
      </b-col>
      <b-col>
        <b-form-select class="fl" v-model="per_page" :options="options_per_page"
          title="Registros por página"></b-form-select>
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
  </div>
</template>
  
<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      load: true,
      fields: [
        {
          key: 'id',
          label: 'Id. rol',
          thStyle: { width: "15%" },
        },
        {
          key: 'name',
          label: 'Rol',
          thStyle: { width: "80%" },
        },

        {
          key: 'actions',
          label: '',
          thStyle: { width: "5%" },
        },
      ],
      items: [],
      current_page: 1,
      per_page: 5,
      options_per_page: [5, 10, 15, 25, 50, 100]
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      await this.getPermission();
      await this.getProfiles();
    },

    getProfiles() {
      this.$http
        .get('/administration/profile/index')
        .then(response => {
          this.items = response.data.data;
        })
        .catch(error => {
          this.notify("Error", error.response.data.message, 'danger')
        }).finally(
          () => {
            this.load = false;
          }
        )
    }
  }
}
</script>
 
  